// Borders
.border-between > [class*=col-]:not(:first-child):after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    background: transparent;
    border: 0;
    border-right: 1px solid $seagreen;
}
@media (min-width: 768px) {
    .border-between-md > [class*=col-]:not(:first-child):after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 0;
        background: transparent;
        border: 0;
        border-right: 1px solid $seagreen;
    }
}
.border-between-2> [class*=col-]:not(:first-child):after {
    left: -1px;
    border-right-width: 2px;
}
.border-between-3> [class*=col-]:not(:first-child):after {
    left: -2px;
    border-right-width: 3px;
}
.border-between-dashed> [class*=col-]:not(:first-child):after {
    border-right-style: dashed;
}

// Hover effect on text
.text-underline-hover:hover,
.text-hover-parent:hover .text-underline-parent-hover {
    text-decoration: underline;
}

// Chatbox
$chatbox-padding: 1.25rem;
.chatbox-title {
    margin: 0 0 $chatbox-padding 0;
    padding: $chatbox-padding;
    background-color: $seagreen-dark;
    color: $teal-light;
}
.chatbox {
    padding: 0 $chatbox-padding;
}
.chatbox-form {
    width: 100%;
    margin-top: $chatbox-padding;
    padding: $chatbox-padding;
    background-color: $seagreen-dark;
}

// Single input form
.single-input-form {
    padding: $chatbox-padding / 4 $chatbox-padding / 2;
    width: 100%;
    background-color: $seagreen-dark;
    border: none;
    border-bottom: 2px solid $seagreen;
    color: $teal-light;
    font-size: $font-size-lg;
    transition: all .25s ease-in-out;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $teal-dark;
        opacity: 1; /* Firefox */
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $teal-dark;
    }
    &::-ms-input-placeholder { /* Microsoft Edge */
        color: $teal-dark;
    }
    &:focus {
        outline-width: 0;
    }
}

// Speech bubbles
.speech-bubble {
    position: relative;
    margin-top: .5rem;
    padding: .25rem .5rem;
    background: $teal-dark;
    border-radius: .4em;

    &.left {
        margin-right: 40px;
    }
    &.right {
        margin-left: 40px;
    }

    &:after {
        content: '';
        position: absolute;
        margin-top: -0.422em;
        width: 0;
        height: 0;
        border: 0.844em solid transparent;
        border-bottom: 0;
    }
    &.left:after {
        left: 0;
        top: 50%;
        margin-left: -0.844em;
        border-right-color: $teal-dark;
        border-left: 0;
    }
    &.right:after {
        right: 0;
        top: 50%;
        margin-right: -0.844em;
        border-left-color: $teal-dark;
        border-right: 0;
    }
}