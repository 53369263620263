//
// Color definitions
//

// default colors
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
        (
            "100": $gray-100,
            "200": $gray-200,
            "300": $gray-300,
            "400": $gray-400,
            "500": $gray-500,
            "600": $gray-600,
            "700": $gray-700,
            "800": $gray-800,
            "900": $gray-900
        ),
        $grays
);

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green: #28a745;
$teal: #27c1ad;
$cyan: #17a2b8;

// project colors
$teal-xlight: #9effbf;
$teal-light: #42FF83;
$teal: #42b983;
$teal-dark: #26885c;
$seagreen-light: #36495d;
$seagreen: #111;
$seagreen-dark: #1f313c;
$seagreen-dark-hover: #243642;

// theme colors
$primary: $teal-light;
$secondary: $teal;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

// stylelint-disable-next-line scss/dollar-variable-default
$colors: (
    "cyan": $cyan,
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,

    "light": $light,
    "dark": $dark,
    "silver": $gray-200,
    "gray": $gray-600,
    "gray-dark": $gray-800,
    "white": $white,
    "black": $black,

    "teal-xlight": $teal-xlight,
    "teal-light": $teal-light,
    "teal": $teal,
    "teal-dark": $teal-dark,
    "seagreen-light": $seagreen-light,
    "seagreen": $seagreen,
    "seagreen-dark": $seagreen-dark,
    "seagreen-dark-hover": $seagreen-dark-hover,
);